import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import '../../styles/styles';
import SVG_THG from '../../components/SVG/SVG_THG';
import { motion, Variants } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench } from '@fortawesome/sharp-regular-svg-icons/faWrench';
import useDebounce from '../../hooks/useDebounce';

const LOGO_DURATION = 1.5;
const LETTERS_DURATION = 1.8;
const PUNCTUATION_EXTRA_DELAY = .5;

const LOGO_VARIANTS: Variants = {
    animate: {
        y: ["100%", "0%"],
        scale: [0, 1.25, 1],
        transition: {
            duration: LOGO_DURATION,
            type: "spring",
            // ease: "easeOut",
            mass: .5,
            stiffness: 120,
            damping: 6
        }
    }
}

const CONSTRUCTION_SIGN_VARIANTS: Variants = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: [0, .5, 1, 1],
        transition: {
            duration: 1.6,
            repeatType: "mirror",
            delay: LOGO_DURATION + LETTERS_DURATION + PUNCTUATION_EXTRA_DELAY + 1,
            repeat: Infinity
        }
    }
}

const LETTER_VARIANTS: Variants = {
    suspend: {
        opacity: 0,
        y: "-100%",
    },
    slam: (delay )=> ({
        opacity: 1,
        y: "0%",
        transition: {
            duration: .25,
            delay: delay,
            type: "spring",
            mass: .5,
            stiffness: 200,
            damping: 7
        }
    })
};

const PUNCTUATION_VARIANTS: Variants = {
    suspend: {
        opacity: 0,
        y: "-100%",
    },
    appear: {
        opacity: 1,
        y: "-100%",
    },
    slam: {
        y: "0%",
        opacity: 1,
        transition: {
            duration: 1,
            type: "spring",
            mass: 1,
            stiffness: 250,
            damping: 12
        }
    }
};

const AnimatedLetter: React.FC<any> = ({ content, delay }) => {
    const [slam, setSlam] = useState<boolean>(false);
    // const [animate, setAnimate] = useState<string>("suspend");
    useDebounce(() => setSlam(true), delay * 1000, []);

    return (
        <motion.div
            initial="suspend"
            variants={LETTER_VARIANTS}
            animate={slam ? "slam" : "suspend"}
            layout
        >
            {content}
        </motion.div>
    )
}

const AnimatedPunctuation: React.FC<any> = ({ content, delay }) => {
    const [animate, setAnimate] = useState<string>("suspend");
    useDebounce(() => setAnimate("appear"), (LETTERS_DURATION + 1.25) * 1000, [])
    useDebounce(() => setAnimate("slam"), (delay + PUNCTUATION_EXTRA_DELAY) * 1000, [])
    console.log(animate)

    return (
        <motion.div
            initial="suspend"
            variants={PUNCTUATION_VARIANTS}
            animate={animate}
            layout
        >
            {content}
        </motion.div>
    )
}

const App: React.FC = () => {
    const UNDER_LETTERS = "Under".split('');
    const CONSTRUCTION_LETTERS = "Construction".split('');
    const N_CHARACTERS = UNDER_LETTERS.length + CONSTRUCTION_LETTERS.length;
    const INDIVIDUAL_DELAY = LETTERS_DURATION / N_CHARACTERS;
    let runningDelay = LOGO_DURATION - .5;

    const CompsA = UNDER_LETTERS.map((v, i) => {
        if (i > 0) runningDelay += INDIVIDUAL_DELAY;
        return <AnimatedLetter key={i} delay={runningDelay} content={v} />;
    })
    runningDelay += INDIVIDUAL_DELAY;
    const CompsB = CONSTRUCTION_LETTERS.map((v, i) => {
        if (i > 0) runningDelay += INDIVIDUAL_DELAY;
        return <AnimatedLetter key={i} delay={runningDelay} content={v} />;
    })

    return (
        <BrowserRouter>
            <div className='w-dvh h-dvh flex flex-col justify-center items-center text-stone-800 text-lg relative'>
                {/* <GrandOverlay /> */}
                <div className='size-1/2 max-w-2xl flex flex-col items-center'>
                    <motion.div
                        className='min-h-12 min-w-12 place-self-stretch flex justify-center w-full max-h-[80%]'
                        variants={LOGO_VARIANTS}
                        initial="initial"
                        animate="animate"
                        layout
                    >
                        <SVG_THG colorFill='red' />
                    </motion.div>
                    <div className='text-teko text-4xl mt-12 flex text-red-600 whitespace-nowrap'>
                        {CompsA}&nbsp;{CompsB}<AnimatedPunctuation content="!" delay={runningDelay + PUNCTUATION_EXTRA_DELAY} />
                    </div>
                    <motion.div
                        className='flex justify-center items-center rounded-full w-24 h-24 max-w-24 max-h-24 border-red-600 border-4 p-4 mt-4'
                        variants={CONSTRUCTION_SIGN_VARIANTS}
                        animate="animate"
                    >
                        <FontAwesomeIcon className='w-[100%] h-[100%] text-red-600' icon={faWrench} />
                    </motion.div>
                </div>
            </div>
        </BrowserRouter>
    )
}

export default App;