// @ts-nocheck
import React, { memo } from 'react';

interface IProps {
    colorFill?: string,
    className?: string
}

const SVG_THG: React.FC<IProps> = ({ colorFill='', className='' }) => {
    return (
        <svg className={className} fill={colorFill} viewBox="0 0 101 138" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.258 54.5592L0.517 109.118L25.227 123.383L49.937 137.648L74.727 123.39L99.517 109.131L99.782 80.0416L100.047 50.9507H61.032H22.017V57.944V64.9372H54.017H86.017V82.7779V100.619L68.35 110.792C58.633 116.387 50.173 120.834 49.551 120.674C48.928 120.514 40.678 115.941 31.218 110.51L14.017 100.638V57.3116V13.9865H28.517H43.017V28.972V43.9575H50.017H57.017V28.972V13.9865H71.517H86.017V28.972V43.9575H93.017H100.017V21.9787V0H50.008H0L0.258 54.5592ZM22.017 33.0141V44.0484L28.767 43.7537L35.517 43.458L35.801 32.7184L36.085 21.9787H29.051H22.017V33.0141ZM64.233 32.7184L64.517 43.458H71.517H78.517L78.801 32.7184L79.085 21.9787H71.517H63.949L64.233 32.7184ZM22.017 84.3694V96.8084L28.363 100.354C31.853 102.304 35.003 103.9 35.363 103.9C35.723 103.9 36.017 96.7065 36.017 87.915V71.9304H29.017H22.017V84.3694ZM43.017 90.3306V108.732L46.517 110.893L50.017 113.054L53.517 110.893L57.017 108.732V90.3306V71.9304H50.017H43.017V90.3306ZM64.017 87.871C64.017 96.6865 64.357 103.9 64.771 103.9C65.186 103.9 68.449 102.201 72.021 100.126L78.517 96.3538V84.3914V72.43L71.267 72.1362L64.017 71.8425V87.871Z" />
        </svg>
    )
}

export default memo(SVG_THG);